<template>
  <div class="d-flex pa-4 rounded hint-with-icon-box box-shadow-light align-center">
    <VIcon
      class="mr-2"
      :color="iconColor"
      size="20"
    >
      {{ icon }}
    </VIcon>
    <p
      data-test="dataTest"
      class="tt-text-body-2 tt-light-mono-100--text mb-0 font-weight-medium"
    >
      {{ text }}
    </p>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'HintWithIcon',

  props: {
    icon: {
      type: String,
      default: 'fa fa-info-circle',
    },
    iconColor: {
      type: String,
      default: 'tt-light-blue',
    },
    text: {
      type: String,
      default: '',
    },
    dataTest: {
      type: String,
      default: '',
    },
  },
});

</script>

<style lang="scss" scope>
  .hint-with-icon-box {
    border: 1px solid map-get($tt-light-mono-8, 'base');
  }
</style>
