
import {
  PropType, defineComponent, toRefs, ref, computed, watch,
} from 'vue';

import { IQuizCurrentQuestionOrder, IQuizCurrentAnswerOrder, IQuizCurrentQuestionChoiceOrder } from '@/domains/quiz';

import CorrectSequenceControl from '@/components/quiz/order/CorrectSequenceControl.vue';

export default defineComponent({
  name: 'QuizQuestionOrder',
  components: {
    CorrectSequenceControl,
  },

  props: {
    question: {
      type: Object as PropType<IQuizCurrentQuestionOrder>,
      required: true,
    },

    answer: {
      type: Object as PropType<IQuizCurrentAnswerOrder>,
      required: true,
    },

    isDisabled: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },

  setup(props, { emit }) {
    const { question, answer } = toRefs(props);

    const value = ref(answer.value.result ?? []);

    const choices = computed<IQuizCurrentQuestionChoiceOrder[]>(() => {
      return question.value.choices;
    });

    const isAnswered = computed<boolean>(() => {
      return value.value.length === choices.value.length;
    });

    const onUpdateAnswerHandle = (currentAnswer: { order: number; choiceId: number }[] = []) => {
      value.value = currentAnswer.flat().filter(({ choiceId }) => Boolean(choiceId));

      if (value.value.length > 0) {
        emit(
          'update',
          value.value.reduce((acc, item) => Object.assign(acc, { [item.order]: item.choiceId }), {}),
        );
      }
    };

    watch(isAnswered, (currentValue: boolean) => {
      emit('is-answered', currentValue);
    }, { immediate: true });

    return {
      value,
      choices,
      isAnswered,
      onUpdateAnswerHandle,
    };
  },
});
