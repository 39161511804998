
import Vue, { PropType } from 'vue';
import Loader from '@/components/ui/Loader.vue';
import { DateTimeISO8601 } from '@/domains/common';
import { formatSeconds, getRemainingTime, getRemainingTimePercent } from '@/helpers/time';

interface ICountdownPlateData {
  timer: number,
  remainingSeconds: number
}

interface IButton {
  text: string,
  isVisible: boolean,
  click: () => void
}

/**
 * Список событий, которые могут быть отправлены родителю:
 *
 * action:click  - событие нажатия на кнопку, передается количество оставшихся секунд
 *
 * start - событие вызывается когда запустился таймер
 *
 * finish  - событие вызывается когда время на таймере закончилось (remainingSeconds = 0)
 *
 * clear - событие вызывается когда таймер очищается
 */

export default Vue.extend({
  name: 'CountdownPlate',

  components: {
    Loader,
  },

  props: {
    /**
     * Общее количество времени в секундах
     */
    timeout: {
      type: Number,
      default: 0,
    },

    /**
     * Время, когда был запущен таймер, DateTimeISO86601
     */
    startedAt: {
      type: String as PropType<DateTimeISO8601 | null>,
      default: null,
    },

    isLoading: {
      type: Boolean as PropType<Boolean>,
      default: false,
    },

    isLoadingBtn: {
      type: Boolean as PropType<Boolean>,
      default: false,
    },

    isDisabledBtn: {
      type: Boolean as PropType<Boolean>,
      default: false,
    },

    /**
     * На каком показателе процентов включать предупреждение о том что время заканчивается, Number
     */
    remainingPercentSecondsWarning: {
      type: Number as PropType<Number>,
      default: 10,
    },

    dataTestLabel: {
      type: String as PropType<String>,
      default: '',
    },

    dataTestValue: {
      type: String as PropType<String>,
      default: '',
    },
  },

  data(): ICountdownPlateData {
    return {
      timer: 0,
      remainingSeconds: 0,
    };
  },

  computed: {
    /**
     * Флаг, указывающий есть ли у нас таймер в компоненте или нет
     */
    hasTimer(): boolean {
      return this.timeout > 0;
    },

    timerMinutes(): number {
      return Math.floor(this.remainingSeconds / 60);
    },

    timerSeconds(): number | string {
      return formatSeconds(Math.floor(this.remainingSeconds % 60));
    },

    iconClasses(): string {
      if (!this.hasTimer) return 'tt-light-mono-46--text';
      return this.remainingTimePercentClasses;
    },

    timerClasses(): string {
      if (!this.hasTimer) return 'tt-light-mono-100--text';
      return this.remainingTimePercentClasses;
    },

    remainingTimePercentClasses(): string {
      return this.hasTime && this.remainingTimePercent > this.remainingPercentSecondsWarning
        ? 'tt-light-mono-100--text'
        : 'tt-light-red--text base';
    },

    /**
     * Время на таймере
     */
    time(): string {
      if (!this.hasTimer) return '';
      return `${this.timerMinutes}:${this.timerSeconds}`;
    },

    timerText(): string {
      if (!this.hasTimer) return this.$t('CountdownPlate.noTime');
      return this.hasTime ? this.$t('CountdownPlate.limitedTime') : this.$t('CountdownPlate.overTime');
    },

    timerTextColor(): string {
      if (!this.hasTimer) return 'tt-light-mono-46--text';
      return this.hasTime ? 'tt-light-mono-46--text' : 'tt-light-red--text base';
    },

    /**
     * Оставшееся время на таймере в процентах
     */
    remainingTimePercent(): number {
      return getRemainingTimePercent(this.remainingSeconds, this.timeout * 60);
    },

    buttons(): IButton[] {
      return [
        {
          text: this.$t('CountdownPlate.buttonComplete'),
          click: () => this.$emit('action:click', this.remainingSeconds),
          isVisible: this.hasTime,
        },
        {
          text: this.hasTimer ? this.$t('CountdownPlate.buttonFinish') : this.$t('CountdownPlate.buttonComplete'),
          click: () => this.$emit('action:click', this.remainingSeconds),
          isVisible: !this.hasTime,
        },
      ];
    },

    hasTime(): boolean {
      return this.remainingSeconds > 0;
    },
  },

  watch: {
    startedAt: {
      handler() {
        this.start();
      },
      immediate: true,
    },

    timeout: {
      handler() {
        this.start();
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.clearTimer();
  },

  mounted() {
    this.start();
  },

  methods: {
    start() {
      if (this.startedAt) {
        this.setTimer(this.startedAt, this.timeout);
      }
    },

    stop() {
      this.clearTimer();
    },

    /** Устанавливаем время и запускаем таймер на фронте */
    setTimer(startedTime: DateTimeISO8601, timeout: number): void {
      this.clearTimer();

      if (!this.hasTimer) return;
      const remainingSeconds = getRemainingTime(startedTime, timeout * 60);

      this.remainingSeconds = Math.max(remainingSeconds, 0);

      this.timerInitialization();
    },

    clearTimer(): void {
      if (this.timer) {
        clearInterval(this.timer);
        this.$emit('clear');
      }
    },

    decreaseTime(): void {
      if (this.remainingSeconds > 0) {
        this.remainingSeconds = getRemainingTime(this.startedAt, this.timeout * 60);
      } else {
        /**
         * Прокидываем событие завершения таймера
         * */
        this.$emit('finish');
        this.clearTimer();
      }
    },

    timerInitialization(): void {
      this.timer = Number(setInterval(this.decreaseTime, 1000));
      this.$emit('start');
    },
  },

});
