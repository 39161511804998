
import Vue, { PropType } from 'vue';

import Draggable from 'vuedraggable';
import ControlArea from '@/components/quiz/order/ControlArea.vue';

import { throttle, DebouncedFunc } from '@/helpers';

import { IQuizCurrentQuestionChoiceOrder } from '@/domains/quiz';

interface IResult {
  order: number;
  choiceId: number;
}

interface IData {
  lists: IQuizCurrentQuestionChoiceOrder[][];
  rightColumn: IQuizCurrentQuestionChoiceOrder[];
  heights: number[];
  answers: IResult[];
}

export default Vue.extend({
  name: 'CorrectSequenceControl',
  components: {
    Draggable,
    ControlArea,
  },
  props: {
    choices: {
      type: Array as PropType<IQuizCurrentQuestionChoiceOrder[]>,
      required: true,
    },
    choicesCount: {
      type: Number,
      required: true,
    },
    value: {
      type: Array as PropType<IResult[]>,
      required: true,
    },
  },
  data(): IData {
    return {
      lists: [],
      rightColumn: [],
      heights: [],
      answers: this.value,
    };
  },
  computed: {
    minHeight(): number {
      // Note: складывается высота всех полей и отступов
      return this.heights.reduce((acc, number) => acc + number, 0) + (16 * (this.heights.length - 1));
    },
  },
  watch: {
    choices: {
      handler(newValue) {
        const resultIds = this.answers.map(({ choiceId }) => choiceId);
        this.rightColumn = [...newValue].filter(({ id }) => !resultIds.includes(id));
      },
      deep: true,
    },
    choicesCount: {
      handler(count: number) {
        if (this.lists.length < count) {
          this.lists.push([]);
        }
      },
      deep: true,
    },
    lists: {
      handler(value) {
        // Note: Идея такая, если в одном из массивов появилось 2 элемента, то мы считаем,
        // что был добавлен новый элемент. После мы бежим по массиву и удаляем первый элемент из него,
        // и выносим его в правую колонку. Массив массивов снова меняется и в каждом массиве оказывается
        // по одному элементу. В этом случае триггерим событие input.

        this.answers = [];

        let isListCrowded = false;

        value.forEach((arr: IQuizCurrentQuestionChoiceOrder[], i: number) => {
          if (arr.length === 2) {
            const firstElement = arr.shift() as IQuizCurrentQuestionChoiceOrder;
            this.rightColumn.push(firstElement);

            isListCrowded = true;
          }

          if (arr.length > 0) {
            this.answers.push({
              order: i + 1,
              choiceId: arr[0]?.id,
            });
          }
        });

        if (!isListCrowded) {
          this.$emit('input', this.answers);
        }
      },
      deep: true,
    },
  },
  async created() {
    this.lists = Array.from({ length: this.choicesCount }, () => ([]));
    const answersId = this.answers.map(({ choiceId }) => choiceId);

    this.answers.forEach((answer) => {
      const currentAnswer: IQuizCurrentQuestionChoiceOrder | undefined = this.choices
        .find(({ id }) => id === answer.choiceId);
      if (currentAnswer) {
        this.lists[answer.order - 1].push(currentAnswer);
      }
    });

    await this.$nextTick();
    this.calcNumberHeight();

    this.rightColumn = this.choices.filter(({ id }) => !answersId.includes(id));

    this.throttleCalcNumberHeight = throttle(this.calcNumberHeight, 250);
    window.addEventListener('resize', this.throttleCalcNumberHeight);
  },
  destroyed() {
    (this.throttleCalcNumberHeight as DebouncedFunc<typeof Function>).cancel();
    window.removeEventListener('resize', this.throttleCalcNumberHeight);
  },
  methods: {
    throttleCalcNumberHeight: () => {},
    calcNumberHeight() {
      // Note: вычисление высоты
      const allAreas: HTMLElement[] = Array.from(this.$el.querySelectorAll('.control-area'));
      this.heights = allAreas.map((el) => {
        if (el.classList.contains('choice')) {
          return Math.ceil(Number(getComputedStyle(el).height.split('px')[0]));
        }

        return 44;
      });
    },
  },
});
