
import {
  PropType, defineComponent, ref, toRefs, computed, watch,
} from 'vue';

import QuestionImages from '@/components/ui/QuestionImages';
import OneToOneComparisonControl from '@/components/ui/OneToOneComparisonControl';

import {
  IQuizCurrentAnswerHash,
  IQuizCurrentAnswerResultHash,
  IQuizCurrentQuestionChoiceHash,
  IQuizCurrentQuestionHash,
  QuizQuestionHashChoiceType,
} from '@/domains/quiz';

interface IValue {
  id: IQuizCurrentQuestionChoiceHash['id'] ;
  title: IQuizCurrentQuestionChoiceHash['title'];
}

export default defineComponent({
  name: 'QuizQuestionHash',

  components: {
    QuestionImages,
    OneToOneComparisonControl,
  },

  props: {
    question: {
      type: Object as PropType<IQuizCurrentQuestionHash>,
      required: true,
    },

    answer: {
      type: Object as PropType<IQuizCurrentAnswerHash>,
      required: true,
    },

    isDisabled: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    const { question, answer } = toRefs(props);

    const innerPairs = ref(answer.value.result ?? []);

    const hasImages = computed(() => {
      return question.value.imageUuids?.length > 0;
    });

    const choices = computed<IQuizCurrentQuestionChoiceHash[]>(() => {
      return question.value.choices;
    });

    const rightValue = computed<IValue[]>(() => {
      return choices.value.filter((value) => value.payload.type === QuizQuestionHashChoiceType.VALUE);
    });

    const leftValue = computed<IValue[]>(() => {
      return choices.value.filter((value) => value.payload.type === QuizQuestionHashChoiceType.KEY);
    });

    const pairs = computed<IQuizCurrentAnswerResultHash[]>({
      get(): IQuizCurrentAnswerResultHash[] {
        return innerPairs.value;
      },
      set(currentAnswer: IQuizCurrentAnswerResultHash[]): void {
        innerPairs.value = currentAnswer;
        emit('update', currentAnswer.reduce((acc, item) => Object.assign(acc, { [item.keyId]: item.valueId }), {}));
      },
    });

    const isAnswered = (() => {
      return innerPairs.value.length === leftValue.value.length;
    });

    watch(isAnswered, (value: boolean) => {
      emit('is-answered', value);
    }, { immediate: true });

    return {
      innerPairs,
      hasImages,
      choices,
      rightValue,
      leftValue,
      pairs,
      isAnswered,
    };
  },
});
