
import Vue from 'vue';
import TTPrompt from '@uikit/components/TTPrompt/TTPrompt.vue';
import { VAppBar } from 'vuetify/lib';

export default Vue.extend({
  name: 'QuizQuestionHeader',
  components: {
    TTPrompt,
    VAppBar,
  },

  inject: ['Names'],

  props: {
    quizName: {
      type: String,
      default: '',
    },
  },

  methods: {
    async onCloseClickHanlder() {
      this.$emit('close');
    },
  },
});
