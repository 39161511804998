
import Vue, { PropType } from 'vue';

import { IQuizQuestion, QuizQuestionStatus } from '@/domains/quiz';

interface IQuizPaginationQuestion extends IQuizQuestion {
  isCurrent: boolean
  isFilled: boolean
  isBlocked: boolean
}

export default Vue.extend({
  name: 'QuizPagination',

  inject: ['Names'],

  props: {
    questions: {
      type: Array as PropType<IQuizQuestion[]>,
      required: true,
    },

    freeFlow: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    paginationQuestions(): IQuizPaginationQuestion[] {
      return this.questions.map((question) => {
        const isCurrent = [QuizQuestionStatus.CURRENT, QuizQuestionStatus.CURRENT_FILLED].includes(question.status);
        const isFilled = [QuizQuestionStatus.FILLED, QuizQuestionStatus.CURRENT_FILLED].includes(question.status);
        const isBlocked = this.freeFlow ? false : [QuizQuestionStatus.BLOCKED].includes(question.status);

        return {
          isCurrent,
          isFilled,
          isBlocked,
          ...question,
        };
      });
    },
  },

  methods: {
    setQuestion(question: IQuizPaginationQuestion) {
      if (question.isCurrent) return;

      if (this.freeFlow) {
        this.$emit('question:set', question.id);
      }
    },
    getDataTestValue(question: IQuizPaginationQuestion) {
      switch (true) {
        case !question.isCurrent && !question.isFilled && !question.isBlocked:
          return 'white';
        case question.isFilled && !question.isCurrent && !question.isBlocked:
          return 'blue';
        case question.isBlocked:
          return 'gray';
        case question.isCurrent && !question.isFilled:
          return 'white-current';
        case question.isCurrent && question.isFilled:
          return 'blue-current';
        default:
          return '';
      }
    },
  },

});
