

/* eslint-disable no-param-reassign */
import {
  defineComponent, PropType,
} from 'vue';

interface IItemProp {
  id: number;
  title: string;
}

interface IItem {
  id: number;
  title: string;
  isSelected: boolean;
  isPicked: boolean;
  dataTestLabel: string;
}

type IPair = {
  keyId: number,
  valueId: number,
};

interface OneToOneComparisonControlData {
  left: IItemProp[];
  right: IItemProp[];
  leftSelected: number | null;
  rightSelected: number | null;
  maxWrappersHeights: number[];
  pairs: IPair[];
}

type ICombinations = Record<IPair['keyId'], IPair['valueId']> & Record<IPair['valueId'], IPair['keyId']>;

export default defineComponent({
  name: 'OneToOneComparisonControl',
  props: {
    leftValue: {
      type: Array as PropType<IItemProp[]>,
      required: true,
    },
    rightValue: {
      type: Array as PropType<IItemProp[]>,
      required: true,
    },
    value: {
      type: Array as PropType<IPair[]>,
      required: true,
    },
  },
  data(): OneToOneComparisonControlData {
    return {
      left: this.leftValue,
      right: this.rightValue,
      leftSelected: null,
      rightSelected: null,
      maxWrappersHeights: [],
      pairs: this.value,
    };
  },

  computed: {
    computedLeft: {
      get(): IItem[] {
        return this.left.map((item) => ({
          ...item,
          isSelected: this.isSelected(item.id),
          isPicked: this.isPicked(item.id),
          dataTestLabel: this.getTestLabelProp(item),
        }));
      },
      set(v: IItem[]) {
        this.left = v;
      },
    },

    computedRight: {
      get(): IItem[] {
        return this.right.map((item) => ({
          ...item,
          isSelected: this.isSelected(item.id),
          isPicked: this.isPicked(item.id),
          dataTestLabel: this.getTestLabelProp(item),
        }));
      },

      set(v: IItem[]) {
        this.right = v;
      },
    },

    /**
     * Вернет список значений (IItem[]) отсортированных в порядке
     * соответствующем списку ключей или, по умолчанию, как пришел с сверху
     *
     * Проходим по всем списку значений и те что
     * находятся в паре устанавливаем на свои места - их индексы соответствуют
     * индексам их пар из списка ключей.
     *
     * Проходим по остальным значениям, которые находятся не в паре и устанавливаем
     * их на доступные места.
     */
    sortedRight(): IItem[] {
      const list = [...this.computedRight];
      const { length } = list;
      const sorted: IItem[] = Array.from({ length });

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < length; i++) {
        const choice = this.computedLeft[i];
        const pairId = this.getPair(choice)?.id;

        if (pairId) {
          const index = list.findIndex((item) => item.id === pairId);

          if (index > -1) {
            const right = list.splice(index, 1);

            if (right.length > 0) {
              // eslint-disable-next-line prefer-destructuring
              sorted[i] = right[0];
            }
          }
        }
      }

      list.forEach((choice) => {
        const newIndex = sorted.findIndex((item) => typeof item === 'undefined');

        if (newIndex > -1) {
          sorted[newIndex] = choice;
        } else {
          sorted.push(choice);
        }
      });

      return sorted;
    },

    bothSelected(): boolean {
      return this.leftSelected !== null && this.rightSelected !== null;
    },

    combinations(): ICombinations {
      return this.value.reduce((acc, { keyId, valueId }) => {
        acc[keyId] = valueId;
        acc[valueId] = keyId;

        return acc;
      }, {} as ICombinations);
    },

    choicesMap(): Record<IItemProp['id'], IItemProp> {
      return [...this.left, ...this.right].reduce((acc, curr) => {
        acc[curr.id] = curr;

        return acc;
      }, {} as Record<IItemProp['id'], IItemProp>);
    },
  },

  watch: {
    leftValue: {
      handler(v) {
        this.left = v;
      },
    },
    rightValue: {
      handler(v) {
        this.right = v;
      },
    },
    computedRight: {
      handler() {
        this.clearStyles();
        this.setHeight();
      },
    },
  },

  mounted() {
    this.setHeight();
  },

  methods: {
    onClickLeftCardHandler(item: IItem) {
      const { id } = item;

      if (this.isSelected(id)) {
        this.leftSelected = null;
      } else {
        this.leftSelected = id;
      }

      if (item.isPicked) {
        const leftIndex = this.computedLeft.findIndex((i) => i.id === id);
        this.breakRelation(leftIndex);
      }

      if (this.bothSelected) {
        this.checkRelation();
        this.clearSelection();
      }
    },

    onClickRightCardHandler(item: IItem) {
      const { id } = item;

      if (this.isSelected(id)) {
        this.rightSelected = null;
      } else {
        this.rightSelected = id;
      }

      if (item.isPicked) {
        const rightIndex = this.sortedRight.findIndex((i) => i.id === id);
        this.breakRelation(rightIndex);
      }

      if (this.bothSelected) {
        this.checkRelation();
        this.clearSelection();
      }
    },

    breakRelation(index: number) {
      const _index = this.pairs.findIndex((item) => item.keyId === this.computedLeft[index].id
          && item.valueId === this.sortedRight[index].id);

      if (index > -1) {
        this.pairs.splice(_index, 1);

        this.computedRight = this.sortedRight;

        this.$emit('input', this.pairs);
      }
    },

    checkRelation() {
      if (this.leftSelected !== null && this.rightSelected !== null) {
        this.pairs.push({
          keyId: this.leftSelected,
          valueId: this.rightSelected,
        });

        this.computedRight = this.sortedRight;
      }

      this.$emit('input', this.pairs);
    },

    clearSelection() {
      this.leftSelected = null;
      this.rightSelected = null;
    },

    // TODO: refactor with id
    hasPair(index: number): boolean {
      return this.computedLeft[index].isPicked && this.sortedRight[index].isPicked;
    },

    async setHeight() {
      await this.$nextTick();
      const leftWrappers: HTMLElement[] = Array.from(this.$el.querySelectorAll('.left .container-wrapper'));
      const leftWrappersHeights = leftWrappers
        .map((element) => (Number(getComputedStyle(element).height.split('px')[0])));

      const rightWrappers: HTMLElement[] = Array.from(this.$el.querySelectorAll('.right .container-wrapper'));
      const rightWrappersHeights = rightWrappers
        .map((element) => (Number(getComputedStyle(element).height.split('px')[0])));

      this.maxWrappersHeights = leftWrappersHeights.map((leftItem, leftIdx) => Math
        .max(leftItem, rightWrappersHeights[leftIdx]));

      leftWrappers.forEach((element, idx) => {
        element.style.height = `${this.maxWrappersHeights[idx]}px`;
      });

      rightWrappers.forEach((element, idx) => {
        element.style.height = `${this.maxWrappersHeights[idx]}px`;
      });
    },

    async clearStyles() {
      await this.$nextTick();

      const allWrappers: HTMLElement[] = Array.from(this.$el.querySelectorAll('.container-wrapper'));

      if (allWrappers != null) {
        allWrappers.forEach((element) => {
          element.style.height = 'auto';
        });
      }
    },

    isSelected(id: IItemProp['id']) {
      return id === this.leftSelected || id === this.rightSelected;
    },

    isPicked(id: number): boolean {
      return Boolean(this.combinations[id]);
    },

    getTestLabelProp(item: IItemProp) {
      switch (true) {
        case this.isSelected(item.id):
          return 'selected';
        case this.isPicked(item.id):
          return `has-pair-${this.getPair(item)?.title}`;
        default:
          return 'non-selected';
      }
    },

    getPair(choice: IItemProp): IItemProp | undefined {
      return this.choicesMap[this.combinations[choice.id]];
    },

    getPosition(choice: IItemProp | undefined) {
      return this.computedLeft.findIndex((item) => item.id === choice?.id)
        ?? -1;
    },
  },
});
