
import {
  defineComponent, ref, PropType, watch, computed, unref, toRefs,
} from 'vue';

import QuestionImages from '@/components/ui/QuestionImages';

import HintWithIcon from '@/components/ui/HintWithIcon/HintWithIcon.vue';

import {
  IQuizCurrentQuestionOpen,
  IQuizCurrentAnswerOpen,
} from '@/domains/quiz';

import { debounce } from '@/helpers';

export default defineComponent({
  name: 'QuizQuestionOpen',

  components: {
    QuestionImages,
    HintWithIcon,
  },

  props: {
    question: {
      type: Object as PropType<IQuizCurrentQuestionOpen>,
      required: true,
    },

    answer: {
      type: Object as PropType<IQuizCurrentAnswerOpen>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { question, answer } = toRefs(props);

    const value = ref<string>('');

    const hasImages = computed(() => {
      return unref(question).imageUuids?.length > 0;
    });

    const hint = computed(() => {
      return unref(question).payload.hint ?? '';
    });

    const isAnswered = computed(() => {
      return unref(value).length > 0;
    });

    const onUpdateValueHandler = (inputValue: string) => {
      value.value = inputValue;
    };

    watch(value, debounce((newValue) => {
      emit('update', { value: newValue });
    }, 250));

    watch(isAnswered, (newValue) => {
      emit('is-answered', newValue);
    }, { immediate: true });

    watch(() => answer.value.result, (newValue) => {
      value.value = newValue?.length ? newValue[0].value : '';
    }, { immediate: true });

    return {
      onUpdateValueHandler,
      value,
      hasImages,
      hint,
    };
  },
});
