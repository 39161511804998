
import Vue, { PropType } from 'vue';

import Draggable from 'vuedraggable';

import { IQuizCurrentQuestionChoiceOrder } from '@/domains/quiz';

export default Vue.extend({
  name: 'ControlArea',
  components: {
    Draggable,
  },
  props: {
    list: {
      type: Array as PropType<IQuizCurrentQuestionChoiceOrder[]>,
      required: true,
    },
    group: {
      type: String,
      required: true,
    },
    dataTestIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isListFull(): boolean {
      return this.list.length > 0;
    },
  },
});
