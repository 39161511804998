
import Vue, { PropType } from 'vue';
import AnswerImages from '@/components/ui/AnswerImages';

import {
  IQuizCurrentAnswerPoly,
  IQuizCurrentAnswerResultPoly,
  IQuizCurrentQuestionChoicePoly,
  IQuizCurrentQuestionPoly,
  QuizQuestionPolyTypeMode,
} from '@/domains/quiz';

interface IQuizPolyAnswerData {
  selectedMono: IQuizCurrentQuestionChoicePoly['id'] | null,
  selectedPoly: IQuizCurrentQuestionChoicePoly['id'][],
}

export default Vue.extend({
  name: 'QuizAnswerPoly',
  components: {
    AnswerImages,
  },
  inject: ['Names'],
  props: {
    isDisabled: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    question: {
      type: Object as PropType<IQuizCurrentQuestionPoly>,
      required: true,
    },
    answer: {
      type: Object as PropType<IQuizCurrentAnswerPoly>,
      required: true,
    },
  },

  data(): IQuizPolyAnswerData {
    return {
      selectedMono: null,
      selectedPoly: [],
    };
  },

  computed: {
    currentQuestion(): IQuizCurrentQuestionPoly {
      return this.question;
    },

    currentAnswer(): IQuizCurrentAnswerPoly {
      return this.answer;
    },

    questionChoices(): IQuizCurrentQuestionChoicePoly[] {
      return this.currentQuestion.choices;
    },

    isMonoQuestion(): boolean {
      return this.currentQuestion.payload.type === QuizQuestionPolyTypeMode.MONO;
    },

    questionChoicesIds(): IQuizCurrentQuestionChoicePoly['id'][] {
      return this.questionChoices.map(({ id }) => id);
    },
  },

  watch: {
    answer: {
      deep: true,
      async handler() {
        this.setSelectedAnswerChoices();
      },
    },
  },
  mounted() {
    this.setSelectedAnswerChoices();
  },

  methods: {
    /**
     * Обновляем состояние выбранных ответов
     */
    changeAnswer() {
      return this.isMonoQuestion ? this.changeMonoAnswer() : this.changePolyAnswer();
    },

    changeMonoAnswer(): void {
      if (this.selectedMono) {
        const answer: Record<IQuizCurrentQuestionChoicePoly['id'], boolean> = this.questionChoicesIds
          .reduce(
            (acc, choiceId) => Object.assign(acc, { [choiceId]: this.selectedMono === choiceId }),
            {},
          );

        this.$emit('update', answer);
      }
    },

    changePolyAnswer(): void {
      const answer: Record<IQuizCurrentQuestionChoicePoly['id'], boolean> = this.questionChoicesIds
        .reduce(
          (acc, choiceId) => Object.assign(acc, { [choiceId]: this.selectedPoly.includes(choiceId) }),
          {},
        );

      this.$emit('update', answer);
    },

    /**
     * Устанавливаем в нужном формате выбранные ранее ответы
     */
    setSelectedAnswerChoices(): void {
      this.clearSelectedItems();
      if (this.isMonoQuestion) this.setRadioChoice();
      if (!this.isMonoQuestion) this.setCheckboxChoices();
    },

    setRadioChoice(): void {
      const answerChoices = this.currentAnswer.result;
      const questionChoices = this.currentQuestion.choices;

      if (answerChoices && answerChoices.length) {
        questionChoices.forEach((qChoice: IQuizCurrentQuestionChoicePoly) => {
          if (this.isAnswerChoiceFinded(answerChoices, qChoice)) this.selectedMono = qChoice.id;
        });
      }
    },

    setCheckboxChoices(): void {
      const answerChoices = this.currentAnswer.result;
      const questionChoices = this.currentQuestion.choices;

      const selectedPoly: IQuizCurrentQuestionChoicePoly['id'][] = [];

      if (answerChoices && answerChoices.length) {
        questionChoices.forEach((qChoice: IQuizCurrentQuestionChoicePoly) => {
          if (this.isAnswerChoiceFinded(answerChoices, qChoice)) selectedPoly.push(qChoice.id);
        });
      }

      this.selectedPoly = selectedPoly;
    },

    clearSelectedItems() {
      this.selectedPoly = [];
      this.selectedMono = null;
    },

    /**
     * Вспомогательные методы
     * */

    isAnswerChoiceFinded(
      answerChoices: IQuizCurrentAnswerResultPoly[],
      questionChoice: IQuizCurrentQuestionChoicePoly,
    ) {
      return answerChoices.find((aChoice: IQuizCurrentAnswerResultPoly) => (aChoice.id === questionChoice.id)
          && aChoice.value);
    },

    hasImages(choice: IQuizCurrentQuestionChoicePoly) {
      return choice.payload.imageUuids && choice.payload.imageUuids.length > 0;
    },
  },
});
