
import {
  PropType, defineComponent, ref, toRefs, computed, watch,
} from 'vue';

import { useI18n } from '@/plugins/vue-i18n';

import QuestionImages from '@/components/ui/QuestionImages';
import QuizAnswerPoly from '@/components/quiz/poly/QuizAnswerPoly.vue';
import {
  IQuizCurrentAnswerPoly,
  IQuizCurrentQuestionChoicePoly,
  IQuizCurrentQuestionPoly,
  QuizQuestionPolyTypeMode,
} from '@/domains/quiz';

export default defineComponent({
  name: 'QuizQuestionPoly',

  components: {
    QuestionImages,
    QuizAnswerPoly,
  },

  props: {
    question: {
      type: Object as PropType<IQuizCurrentQuestionPoly>,
      required: true,
    },
    answer: {
      type: Object as PropType<IQuizCurrentAnswerPoly>,
      required: true,
    },
    isDisabled: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },

  setup(props, { emit }) {
    const { question, answer } = toRefs(props);

    const { t } = useI18n();

    const value = ref(answer.value.result ?? []);

    const subtitle = computed(() => {
      return question.value.payload.type === QuizQuestionPolyTypeMode.POLY
        ? t('QuizQuestionPoly.description.poly')
        : t('QuizQuestionPoly.description.mono');
    });

    const hasImages = computed(() => {
      return question.value.imageUuids?.length > 0;
    });

    const isAnswered = computed(() => {
      return Object.values(value.value).filter((currentAnswer) => currentAnswer.value).length > 0;
    });

    const onUpdateAnswerHandler = (answers: Record<IQuizCurrentQuestionChoicePoly['id'], boolean>) => {
      value.value = Object.entries(answers).reduce((acc, [id, currentValue]) => {
        acc.push({
          id: Number(id),
          value: currentValue,
        });

        return acc;
      }, [] as IQuizCurrentAnswerPoly['result']);

      emit('update', answers);
    };

    watch(isAnswered, (newValue: boolean) => {
      emit('is-answered', newValue);
    }, { immediate: true });

    return {
      value,
      subtitle,
      hasImages,
      isAnswered,
      onUpdateAnswerHandler,
    };
  },
});
